* {
  font-family: arial;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 40px;
  height: 40px;
  padding: 0 !important;
  border-radius: 20px;
  text-align: center;
  font-size: 18px;
  line-height: 40px;

  i {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
}

.imgCover {
  max-width: 100%;
  width: 300px;
  height: 140px;
  object-fit: cover;
}

// font size
@for $i from 10 through 60 {
  .font-#{$i} {
    font-size: #{$i}px;

    > * {
      font-size: #{$i}px;
    }
  }
}
