$xs: 0;
$sm: 540px;
$md: 720px;
$lg: 960px;
$xl: 1108px;
$xxl: 1108px;

/*containers size*/
$container-max-widths: (
        sm: $sm,
        md: $md,
        lg: $lg,
        xl: $xl,
        xxl: $xxl
);

$grid-breakpoints: (
        xs: $xs,
        sm: $sm,
        md: $md,
        lg: $lg,
        xl: $xl,
        xxl: $xxl
);

/*fonts*/




/*colors*/
$primary: #063d46;
$dark: #272830;
$info: #888888;
$secondary: #063d46;
$light: #D9D9D9;
$white: #ffffff;
$success: #62E57C;
$danger: #f00;

$theme-colors: (
        "primary":    $primary,
        "secondary":  $secondary,
        "success":    $success,
        "info":       $info,
        // "warning":    $warning,
        "danger":     $danger,
        "light":      $light,
        "dark":       $dark,
        "white":       $white
);
