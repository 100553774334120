@import "../../assets/scss/variables";
.menu {
    .menuLink {
        font-size: 13px;
        color: $info;
        &.active {
            color: $dark;
        }
        &:hover {
            cursor: pointer;
        }
    }
}
