.bannerAuto {
    background-size: cover;
    background-position: center;
    height: 100vh;
}
.blocoDados {
    padding: 40px 20px;
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
    @media (max-width: 960px) {
        min-height: 100vh;
    }
}
