@import "../../assets/scss/variables";

.topoUser {
    position: relative;
    &:before {
        content: "";
        width: 100%;
        height: calc(100% + 140px);
        position: absolute;
        top: 0;
        background: $primary;
    }
    .dropdownToggle {
        &:after {
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
}
